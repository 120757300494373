import React from "react";
import classConcat from "../../../../util/ClassConcat";
import Brand from "../../Brand/Brand";
import FooterLinkGroup from "./FooterLinkGroup/FooterLinkGroup";
import twitter from "../../../../assets/twitter-icon.png";
import styles from "./FreeFooter.module.css";

const FreeFooter = () => {
  return (
    <footer
      className={classConcat("container-fluid", "px-3", "py-4", styles.footer)}
    >
      <div className={classConcat("row", styles.row)}>
        <div className={classConcat("col-md-4", "py-3", styles.col)}>
          <Brand showText />
        </div>
        <div className={classConcat("col-md-4", "px-5", "py-2", styles.col)}>
          <FooterLinkGroup
            links={[
              {
                to: "/case-study",
                text: "Case Study",
              },
              {
                to: "/sample-apps",
                text: "Sample Apps",
              },
              {
                to: '/funding',
                text: 'Funding',
              },
              {
                to: "/build-phases",
                text: "Build Phases",
              },
              {
                to: "/testimonials",
                text: "Testimonials",
              },
              {
                to: "/payments",
                text: "Payments",
              },
              {
                to: "/jobs",
                text: "Jobs",
              },
            ]}
          />
        </div>
        <div className={classConcat("col-md-4", "px-5", "py-2", styles.col)}>
          <FooterLinkGroup
            links={[
              {
                to: "/copyright",
                text: "Copyright",
              },
              {
                to: "/about-us",
                text: "About Us",
              },
              {
                to: "/terms-of-service",
                text: "Terms of Service",
              },
              {
                to: "/privacy-policy",
                text: "Privacy Policy",
              },
              {
                to: "/contact-us",
                text: "Contact Us",
              },
              {
                to: "https://twitter.com/OpZoomFund?ref_src=twsrc%5Etfw",
                text: (
                  <>
                    <img src={twitter} width="25px" height="25px" /> Follow
                    @OpZoomFund
                  </>
                ),
                target: "_blank",
              },
            ]}
          />
        </div>
      </div>
    </footer>
  );
};

export default FreeFooter;
